<template>
    <footer class="text-center text-lg-start pt-5">
        <div
            class="alert alert-success alert-dismissible fade show position-fixed top-0"
            role="alert"
            v-if="alert"
            style="width:100%; z-index:1000;"
        >
            <strong>Subscribed successfully!</strong>
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
            ></button>
        </div>
        <section class="container text-center border-bottom">
            <div class="me-5 d-none d-lg-block">
                <h1 style="font-size : 40px;font-weight: bold">
                    Pyae Sone Shin - Buying off bought gold jewellery with
                    current market price
                </h1>
            </div>
        </section>
        <!-- Section: Social media -->

        <!-- Section: Links  -->
        <section>
            <div class="container text-center text-md-start mt-5">
                <!-- Grid row -->
                <div class="row">
                    <!-- Grid column -->
                    <div class="col-md-6 col-lg-3 col-xxl-3 mx-auto mb-4">
                        <!-- Links -->
                        <h6 class="fw-bold mb-4">
                            Quick links
                        </h6>
                        <ul class="navbar-nav mr-auto">
                            <li class="nav-item">
                                <router-link
                                    :to="{ name: 'Home' }"
                                    class="nav-link"
                                    >Welcome</router-link
                                >
                            </li>
                            <li class="nav-item dropdown">
                                <a
                                    class="nav-link dropdown-toggle"
                                    href="#"
                                    id="navbarDropdown"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    All Jewellry
                                </a>
                                <div
                                    class="dropdown-menu dropdown-bg-width"
                                    aria-labelledby="navbarDropdown"
                                >
                                    <!-- Categories -->
                                    <li>
                                        <p
                                            style="margin: 0 1rem;
                                        font-size: 1.2rem;
                                        font-weight: 700;"
                                        >
                                            Categories
                                        </p>
                                    </li>
                                    <li
                                        v-for="(category, index) in categories"
                                        :key="index"
                                    >
                                        <router-link
                                            style="color: black"
                                            class="dropdown-list fw-normal"
                                            :to="{
                                                name: 'ProductList',
                                                query: {
                                                    category_id: encryptAES(
                                                        category.id,
                                                        'pss',
                                                    ),
                                                    category_name:
                                                        category.name,
                                                },
                                            }"
                                            >{{ category.name }}</router-link
                                        >
                                    </li>
                                    <div class="dropdown-divider"></div>

                                    <!-- Collections -->
                                    <li>
                                        <p
                                            style="margin: 0 1rem;
                                        font-size: 1.2rem;
                                        font-weight: 700;"
                                        >
                                            Collections
                                        </p>
                                    </li>
                                    <li
                                        v-for="(collection,
                                        index) in collections"
                                        :key="`${collection.name}-${index}`"
                                    >
                                        <router-link
                                            style="color: black"
                                            class="dropdown-list fw-normal"
                                            :to="{
                                                name: 'ProductList',
                                                query: {
                                                    collection_id: encryptAES(
                                                        collection.id,
                                                        'pss',
                                                    ),
                                                    collection_name:
                                                        collection.name,
                                                },
                                            }"
                                            >{{ collection.name }}</router-link
                                        >
                                    </li>
                                </div>
                            </li>
                            <li class="nav-item">
                                <router-link
                                    :to="{ name: 'Blog' }"
                                    class="nav-link"
                                    >Blog & Articles
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <a
                                    class="nav-link"
                                    style="cursor: pointer;"
                                    @click="pdfViewerModal = true"
                                    >How to buy</a
                                >
                            </li>
                        </ul>
                    </div>
                    <!-- Grid column -->

                    <!-- Grid column -->
                    <div class="col-md-6 col-lg-3 col-xxl-3 mx-auto mb-4">
                        <!-- Links -->
                        <h6 class="fw-bold mb-4">
                            Information
                        </h6>
                        <ul class="navbar-nav mr-auto">
                            <li class="nav-item">
                                <router-link
                                    :to="{ name: 'Blog' }"
                                    class="nav-link"
                                    >Exchange & Return</router-link
                                >
                            </li>
                            <li class="nav-item">
                                <router-link
                                    :to="{ name: 'Terms' }"
                                    class="nav-link"
                                    >Terms & Service
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link
                                    :to="{ name: 'Blog' }"
                                    class="nav-link"
                                    >Delivering Policy
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link
                                    :to="{ name: 'Blog' }"
                                    class="nav-link"
                                    >Refund Policy
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link
                                    :to="{ name: 'Blog' }"
                                    class="nav-link"
                                    >Contact
                                </router-link>
                            </li>
                        </ul>
                    </div>
                    <!-- Grid column -->

                    <!-- Grid column -->
                    <div class="col-md-6 col-lg-3 col-xxl-3 mx-auto mb-4">
                        <!-- Links -->
                        <h6 class="fw-bold mb-4">
                            Payment
                        </h6>
                        <ul class="navbar-nav mr-auto">
                            <div class="mb-4">
                                <li style="color: #3CB207;">
                                    <ShieldCheckIcon />
                                    <span>Secure Payment</span>
                                </li>
                                <div
                                    class="d-flex align-items-center gap-1 justify-content-center justify-content-md-start justify-content-lg-start"
                                >
                                    <li>
                                        <img
                                            src="@/assets/img/banks/visa.png"
                                            class="payment"
                                        />
                                    </li>
                                    <li>
                                        <img
                                            src="@/assets/img/banks/pngegg.png"
                                            class="payment"
                                        />
                                    </li>
                                    <li>
                                        <img
                                            src="@/assets/img/banks/jcb.png"
                                            class="payment"
                                        />
                                    </li>
                                    <li>
                                        <img
                                            src="@/assets/img/banks/unionpay.png"
                                            class="payment"
                                        />
                                    </li>
                                    <li>
                                        <img
                                            src="@/assets/img/banks/kbzbank.svg"
                                            class="payment"
                                            style="height: 1.5rem"
                                        />
                                    </li>
                                    <li>
                                        <img
                                            src="@/assets/img/banks/yoma.png"
                                            class="payment"
                                            style="height: 1.5rem; width: 1.5rem"
                                        />
                                    </li>
                                </div>
                            </div>
                            <li class="nav-item">
                                <router-link
                                    :to="{ name: 'Blog' }"
                                    class="nav-link"
                                    >How to made payment?</router-link
                                >
                            </li>
                        </ul>
                    </div>
                    <!-- Grid column -->

                    <!-- Grid column -->
                    <div class="col-md-6 col-lg-3 col-xxl-3 mx-auto mb-4">
                        <!-- Links -->
                        <h6 class="fw-bold mb-4">
                            Subscribe Pyae Sone Shin Newsletter !
                        </h6>
                        <ul class="navbar-nav mr-auto">
                            <li
                                class="nav-item mb-4"
                                v-for="(subscriptionType,
                                index) in allsubscriptiontypes"
                                :key="index"
                            >
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        :value="parseInt(subscriptionType.id)"
                                        id="flexCheckDefault"
                                        v-model="checksubscriptionTypes"
                                    />
                                    <label
                                        class="form-check-label"
                                        for="flexCheckDefault"
                                        >{{ subscriptionType.name }}</label
                                    >
                                </div>
                            </li>
                            <div
                                class="d-flex align-items-start justify-content-center"
                            >
                                <div
                                    class="me-2 flex-grow-1 mb-4 mb-sm-0 w-100"
                                >
                                    <input
                                        v-model="subscriber_mail"
                                        type="text"
                                        :placeholder="
                                            $t(
                                                'home.contactUsArea.input.placeholder',
                                            )
                                        "
                                        class="form-control ps-2"
                                    />
                                    <div
                                        class="error invalid-feedback d-block"
                                        v-if="
                                            submitStatus == 'ERROR' &&
                                                !$v.subscriber_mail.required
                                        "
                                    >
                                        This field is required.
                                    </div>
                                    <div
                                        class="error invalid-feedback d-block"
                                        v-if="
                                            submitStatus == 'ERROR' &&
                                                !$v.subscriber_mail.email
                                        "
                                    >
                                        This field must be email.
                                    </div>
                                </div>
                                <main-button
                                    :name="$t('home.contactUsArea.button')"
                                    @clicked="subscribe"
                                />
                            </div>
                        </ul>
                    </div>
                    <!-- Grid column -->
                </div>
                <!-- Grid row -->
            </div>
        </section>
        <!-- Section: Links  -->

        <!-- Copyright -->
        <div class="text-center p-2" style="background-color: #3CB207;">
            COPYRIGHT &copy;
            {{ `${new Date().getFullYear().toString()}` }} Pyae Sone Shin
            Company. All rights served
        </div>
        <!-- Copyright -->
    </footer>
    <!-- Footer -->
</template>

<script>
import { mapGetters } from 'vuex';
import { ShieldCheckIcon } from '@vue-hero-icons/outline';
import Button from '../reusable/Button';
import createSubscription from '@/graphql/mutations/home/Subscribe.gql';

// vuelidate
import encryptDecrypt from '@/mixins/PromotionEncryptDecrypt';
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';

export default {
    mixins: [validationMixin, encryptDecrypt],

    components: {
        ShieldCheckIcon,
        'main-button': Button,
    },

    data() {
        return {
            subscriber_mail: '',
            alert: false,
            submitStatus: '',
            // newsletters: [
            //     'New Jewellery',
            //     'Offers & Promotion',
            //     'Pyae Sone Shin Weekly Collecions',
            //     'New Videos, Blogs & Articles',
            // ],
            checksubscriptionTypes: [],
        };
    },
    computed: {
        ...mapGetters({
            collections: 'home/getCollections',
            categories: 'home/getCategories',
            allsubscriptiontypes: 'home/getAllSubscriptionTypes',
        }),
    },
    validations: {
        subscriber_mail: {
            required,
            email,
        },
    },

    methods: {
        subscribe() {
            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR';
            } else {
                this.$apollo
                    .mutate({
                        mutation: createSubscription,

                        variables: {
                            email: this.subscriber_mail,
                            subscription_type_id: this.checksubscriptionTypes,
                        },
                    })
                    .then(response => {
                        console.log(response.data);
                        this.alert = true;
                        this.subscriber_mail = '';
                        this.checksubscriptionTypes = [];
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        },
    },
};
</script>

<style lang="scss">
@import '@/sass/abstract/variables.scss';
@import '@/sass/components/navbar.scss';

.nav-link {
    color: var(--color-black);
    padding: 0;
    margin-bottom: 16px;
    &:hover {
        color: var(--color-black);
    }
}
.payment {
    width: 2rem;
    height: 2rem;
}
.footer {
    .subscribe-alert {
        top: 5px;
        right: 5px;
    }
    @media (max-width: 991.98px) {
        .footer-image {
            background: #f8f9fa;
        }
    }
}
@media (min-width: 992px) {
    .dropdown-bg-width {
        width: max-content;
    }
}
</style>
